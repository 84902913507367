.controls {
	display: flex;
	justify-content: space-between;
	margin-top: auto;
}

.controlButton {
	width: 229px;
	height: 60px;
}
