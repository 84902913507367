.container {
	margin-bottom: 24px;
}

.switcherContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}

.switcher__toggler span {
	opacity: 1;
}

.switcher__title {
	font: var(--gilroy-Medium-24);
}

.linksContainer {
	display: flex;
	flex-direction: column;
	padding: 16px;
	background-color: var(--color-white100);
}
