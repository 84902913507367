.repertoireSong__header {
	display: flex;
	height: 48px;
	background: var(--color-light-grey);
}

.repertoireSong__header > button {
	font: var(--gilroy-Bold-16-19);
}

.header {
	background: var(--color-white);
	padding: 12px 30px 12px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--color-dark-grey);
}

.title {
	font: var(--gilroy-Medium-40);
	letter-spacing: 1px;
	margin-left: 16px;
}

.title_album {
	font: var(--gilroy-Medium-24);
	line-height: 28px;
	letter-spacing: 0.96px;
	margin: 0px 16px 0px 8px;
}

.ean {
	font: var(--gilroy-Medium-18);
}

.ean_number {
	margin-left: 10px;
	font: var(--gilroy-Medium-18);
}

.repertoireSong__main {
	padding: 40px 30px 100px;
}

.mm {
	position: absolute;
	top: 20px;
	left: 55vw;
	z-index: 111111111;
	border: none;
	background: darkgreen;
	color: white;
	padding: 5px 10px;
}

.headerButton {
	cursor: pointer;
}

.tabContent {
	display: flex;
	align-items: center;
}

.tabContent img {
	width: 24px;
	height: 24px;
	margin-top: 3px;
	margin-right: 5px;
}

.rightSideBtnsWrapper {
	display: flex;
	align-items: center;
}

.shipmentActionsWrapper {
	position: relative;
}

.shipmentBtnsWrapper {
	z-index: 1;
	position: absolute;
	right: 0px;
	top: 44px;
	width: 250px;
	height: fit-content;
	margin-left: 24px;
	background-color: var(--color-dark-grey);
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	letter-spacing: 0.16px;
}

.shipmentBtnsHeader {
	position: absolute;
	right: 0px;
	top: -60px;
	width: 133px;
	height: 68px;
	padding: 6px 16px 6px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 24px;
	text-decoration: none;
	font: var(--gilroy-Medium-16);
	color: var(--color-white);
	background-color: var(--color-dark-grey);
}

.shipmentBtnsHeader span {
	padding-bottom: 2px;
}

.Chevron {
	height: 24px;
	width: 24px;
	cursor: pointer;
}

.actionBtn {
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	align-items: center;
	width: 133px;
	height: 36px;
	margin-left: 16px;
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	text-decoration: none;
	color: var(--color-dark-grey);
	border: none;
	border-radius: 50px;
	padding: 6px 16px 6px 20px;
	background-color: var(--color-light-green);

	cursor: pointer;
}

.actionBtn:focus {
	outline: none;
}

.actionBtn span {
	padding-bottom: 2px;
}

.editAfterShipmentBtn,
.smartlinkBtn {
	display: flex;
	align-items: center;
	padding: 14px 16px;
	text-decoration: none;
	color: var(--color-white);
	cursor: pointer;
}

.editAfterShipmentBtn,
.editAfterShipmentBtnDisabled,
.smartlinkBtn {
	width: 100%;
	border: none;
	background: transparent;
	text-align: left;
	font: var(--gilroy-Medium-16);
}

.editAfterShipmentBtnDisabled {
	display: flex;
	align-items: center;
	padding: 14px 16px;
	text-decoration: none;
	color: var(--color-grey);
	font: var(--gilroy-Medium-16);
}

.smartlinkBtnContainer {
	display: flex;
	justify-content: space-between;
	padding-right: 16px;
}

.shipmentBtn,
.fallbackBtn {
	display: block;
	padding: 14px 16px;
	text-decoration: none;
	color: var(--color-white);
	cursor: pointer;
}

.editAfterShipmentBtn:hover,
.smartlinkBtn:hover,
.shipmentBtn:hover,
.fallbackBtn:hover {
	text-decoration: line-through;
}

.shipmentBtnDisabled,
.fallbackBtnDisabled {
	display: block;
	padding: 14px 16px;
	text-decoration: none;
	color: var(--color-grey);
	font: var(--gilroy-Medium-16);
}

.editAfterShipmentBtnDisabled:hover {
	cursor: text;
	text-decoration: none;
}

.btnIcon {
	margin-right: 6px;
}

.editAfterShipmentBtn .loader,
.editAfterShipmentBtnDisabled .loader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	margin-top: 0;
	margin-left: 10px;
	animation: spin 2s linear infinite;
	overflow: visible;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media screen and (min-height: 770px) {
	.repertoireSong__main {
		padding: 40px 30px;
	}
}
