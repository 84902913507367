.container {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	min-height: 96px;
	padding: 12px;
	border: 1px solid var(--color-grey100);
}

.container button {
	background: transparent;
	border: none;
	cursor: pointer;
}

.controls {
	display: flex;
	gap: 8px;
}

.dataBlock {
	display: grid;
	grid-template-columns: 2fr 10fr;
	grid-column-gap: 12px;
	grid-row-gap: 12px;
}

.dataTitle {
	font: var(--gilroy-Medium-14);
	color: var(--color-grey);
}

.dataInfoOutlets {
	max-width: 400px;
}

.dataInfo,
.dataInfoOutlets span {
	font: var(--gilroy-Medium-14);
}

.outletSpan {
	display: inline-block;
	margin-right: 4px;
	margin-bottom: 4px;
	padding: 2px 4px;
	background: var(--color-light-grey);
}
