.modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.container {
	width: 519px;
	margin-right: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.bodyContainer {
	display: flex;
	flex-direction: column;
	width: 519px;
	height: 100vh;
	padding: 24px;
	overflow: auto;
	color: var(--color-dark-grey);
	background-color: var(--color-white);
}

.bodyWrapper :global .another-lang {
	opacity: 0.5 !important;
}

.bodyWrapper {
	display: flex;
	flex-direction: column;
	position: relative;
}

.bodyHeader {
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
}

.bodyHeader button {
	border: none;
	background: transparent;
	cursor: pointer;
}

.bodyTitle {
	display: flex;
	justify-content: space-between;
	font: var(--gilroy-Medium-30);
	color: var(--color-dark-grey);
}

.titleButtons {
	display: flex;
	gap: 16px;
}

.bodySubtitle {
	margin-top: 4px;
	font: var(--gilroy-Medium-18-24);
	color: var(--color-grey);
}

.bodyTopWrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.iframeContainer {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: calc(100vw - 519px);
	box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.smartlinkIframe {
	width: 100%;
	height: 100%;
	border: none;
}

.controls {
	display: flex;
	justify-content: space-between;
	margin-top: auto;
}

.controlButton {
	width: 229px;
	height: 60px;
}
