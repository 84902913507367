.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 16px;
	background-color: var(--color-white);
	height: 100%;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.title {
	margin-bottom: 28px;
	font: var(--gilroy-Medium-24-28);
}

.tableHeading {
	display: grid;
	grid-template-columns: 2fr 1fr 180px;
	gap: 15px;
}

.tableHeadingTitle {
	font: var(--gilroy-Medium-14);
	color: var(--color-grey);
}

.tableHeadingTitle:first-child {
	padding-left: 50px;
}

.selectedPeriod {
	max-width: 155px;
}

.empty {
	display: flex;
	align-items: center;
	flex: 1;
}

@media (min-width: 768px) {
	.tableHeading {
		grid-template-columns: 2fr 2fr 180px;
	}
}
