.container {
	margin-bottom: 40px;
}

.title {
	font: var(--gilroy-Medium-24);
	margin-bottom: 16px;
}

.linksContainer {
	display: flex;
	flex-direction: column;
	padding: 16px;
	background-color: var(--color-white100);
}
