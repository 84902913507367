.button {
	position: relative;
	margin: 0;
	padding: 0;
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;
}

.disable {
	cursor: not-allowed;
	opacity: 0.5;
}
