.title {
	font: var(--gilroy-Medium-24);
	margin-bottom: 16px;
}

.container {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
}
