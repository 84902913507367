.copyButton {
	position: relative;
	margin: 0;
	padding: 0;
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;
}

.copyButton svg {
	transition: opacity 0.2s linear;
}

.copyButton:hover svg {
	opacity: 0.7;
}

.copiedTooltip {
	position: absolute;
	top: -30px;
	left: 50%;
	padding: 6px;
	transform: translateX(-50%);
	font: var(--gilroy-Medium-12);
	color: var(--color-dark-grey);
	background-color: var(--color-white);
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
	animation: showTooltip 0.2s linear alternate;
}
