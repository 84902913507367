.wrapper {
	margin: 40px 30px;
}

.header {
	margin-bottom: 24px;
}

.title {
	margin-bottom: 14px;
	font: var(--gilroy-Medium-24);
	color: var(--color-dark-grey);
	letter-spacing: 1px;
}

.searchInput {
	width: 504px;
}
